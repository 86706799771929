import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LoginData, useForgotPassword } from "../../api/auth";
import { saveUserData } from "../../services/user";


const ForgotPassword = () => {
  const { mutate } = useForgotPassword();
  const navigate = useNavigate();
  const toast = useToast();
  const { handleSubmit, register, formState: { errors, isSubmitting }, } = useForm<LoginData>();

  const onSubmit: SubmitHandler<LoginData> = (values) => {
    mutate(
      { email: values.email },
      {
        onSuccess: (data) => {
          saveUserData(data);
          navigate("/");
        },
        onError: (error) => {
          toast({
            title: `Login failed`,
            status: "error",
            isClosable: true,
          })
        }
      }
    );
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Passwort vergessen</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>

              <FormControl id="email" isInvalid={!!errors.email} isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  id="email"
                  {...register("email", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <Button
                type="submit"
                isLoading={isSubmitting}
              >
                neues Passwort senden
              </Button>
            </Stack>
        </form>
      </Box>
    </Stack>
    </Flex >
  );
};

export default ForgotPassword;

import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/react";
import Pagecontainer from "../../components/Pagecontainer";

const SignupSuccess = () => {
  return <Pagecontainer>
    <Alert
      status='success'
      variant='subtle'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      textAlign='center'
      height='200px'
    >
      <AlertIcon boxSize='40px' mr={0} />
      <AlertTitle mt={4} mb={1} fontSize='lg'>
        Erfolgreich registriert!
      </AlertTitle>
      <AlertDescription maxWidth='sm'>
        Sie erhalten eine Email mit einem Bestätigungslink und weiteren Anweisungen.
      </AlertDescription>
    </Alert>
  </Pagecontainer>;
};

export default SignupSuccess;

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { LoginData, useLogin } from "../../api/auth";
import { saveUserData } from "../../services/user";


const Login = () => {
  const { mutate } = useLogin();
  const navigate = useNavigate();
  const toast = useToast();
  const { handleSubmit, register, formState: { errors, isSubmitting }, } = useForm<LoginData>();

  const onSubmit: SubmitHandler<LoginData> = (values) => {
    mutate(
      { email: values.email, password: values.password },
      {
        onSuccess: (data) => {
          saveUserData(data);
          navigate("/");
        },
        onError: (error) => {
          toast({
            title: `Login failed`,
            status: "error",
            isClosable: true,
          })
        }
      }
    );
  };

  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
    >
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}>Anmelden</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
        >
          <Stack spacing={4}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl id="email" isInvalid={!!errors.email} isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  id="email"
                  {...register("email", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isRequired id="password" isInvalid={!!errors.password}>
                <FormLabel>Passwort</FormLabel>
                <Input
                  type="password"
                  id="password"
                  {...register("password", {
                    required: "This is required",
                  })}
                />
                <FormErrorMessage>
                  {errors.password && errors.password.message}
                </FormErrorMessage>
              </FormControl>
              <Stack spacing={4}>
                <Stack align={"start"} justify={"space-between"}>
                  <Link as={RouterLink} to={"/forgot"} color={"red.300"}>Passwort vergessen?</Link>
                </Stack>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Anmelden
                </Button>
                <Link as={RouterLink} to={"/signup"} color={"green.400"}>
                  Registriere dich hier!
                </Link>
              </Stack>
            </form>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;

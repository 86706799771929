import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  useToast,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from 'react-hook-form';
import { SignUpData, useSignUp } from '../../api/auth';


const Signup = () => {
  const { mutate } = useSignUp();
  const navigate = useNavigate();
  const toast = useToast();
  const { handleSubmit, register, formState: { errors, isSubmitting }, } = useForm<SignUpData>();

  const [showPassword, setShowPassword] = useState(false);


  const onSubmit: SubmitHandler<SignUpData> = (values) => {
    mutate(
      { email: values.email, password: values.password, firstName: values.firstName, lastName: values.lastName },
      {
        onSuccess: (data) => {
          navigate("/signup/success")
        },
        onError: (error) => {
          toast({
            title: `Login failed`,
            status: "error",
            isClosable: true,
          })
        }
      }
    );
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            Registrieren
          </Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <form onSubmit={handleSubmit(onSubmit)}>

              <HStack>
                <Box>
                  <FormControl id="firstName" isRequired isInvalid={!!errors.firstName}>
                    <FormLabel>First Name</FormLabel>
                    <Input type="text"
                      id="firstname"
                      {...register("firstName", {
                        required: "This is required",
                      })} />
                    <FormErrorMessage>
                      {errors.firstName && errors.firstName.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl id="lastname" isInvalid={!!errors.lastName}>
                    <FormLabel>Last Name</FormLabel>
                    <Input type="text" id="lastname"
                      {...register("lastName", {
                        required: "This is required",
                      })} />
                    <FormErrorMessage>
                      {errors.lastName && errors.lastName.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </HStack>
              <FormControl id="email" isRequired isInvalid={!!errors.email}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  id="email"
                  {...register("email", {
                    required: "This is required",
                  })} />
              </FormControl>
              <FormControl id="password" isRequired isInvalid={!!errors.password}>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input type={showPassword ? 'text' : 'password'}
                    id="password"
                    {...register("password", {
                      required: "This is required",
                    })} />
                  <InputRightElement h={'full'}>
                    <Button
                      variant={'ghost'}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }>
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                  <FormErrorMessage>
                    {errors.password && errors.password.message}
                  </FormErrorMessage>
                </InputGroup>
              </FormControl>
              <Stack spacing={10} pt={2}>
                <Button
                  loadingText="Submitting"
                  size="lg"
                  type='submit'
                  color={'white'}
                  isLoading={isSubmitting}>
                  Sign up
                </Button>
              </Stack>
            </form>
            <Stack pt={6}>
              <Text align={'center'}>
                Bereits einen Account? <Link color={'blue.400'} as={RouterLink} to={'/login'}>Login</Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
export default Signup
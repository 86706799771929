import { Heading, Text } from "@chakra-ui/react";
import axios from "axios";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Pagecontainer from "../../components/Pagecontainer";
import config from "../../services/config";

const ConfirmEmail = () => {
  const navigate = useNavigate();

  const confirmMail = useMutation(
    (hash?: string) => {
      return axios.post(`${config.API_SERVER}v1/auth/email/confirm`, {
        hash: hash,
      });
    },
    {
      onSuccess: () => navigate("/login"),
    }
  );

  const { hash } = useParams();

  return (
    <Pagecontainer>
      <Heading>Email confirmation</Heading>
      <Text onClick={() => confirmMail.mutate(hash)}>Click to confirm</Text>
    </Pagecontainer>
  );
};

export default ConfirmEmail;

import { useMutation, useQuery, useQueryClient } from "react-query";
import config from "../services/config";
import apiClient from "./_client";

type League = {
  id: number;
  name: string;
  players: Array<Player>;
};

type Player = {
  id: number;
  points: number;
  user: User;
  win: number;
  loss: number;
  canBeChallenged: boolean;
};

type User = {
  id: number;
  firstName: string;
  lastName: string;
  playerName?: string;
};

export const useScoreboard = (id: string) => {
  return useQuery({
    queryKey: ["leaderboard"],
    queryFn: async (): Promise<League> => {
      const { data } = await apiClient.get(
        `${config.API_SERVER}v1/league/${id}`
      );
      return data;
    },
  });
};

type MatchRequest = {
  opponentId: number;
  leagueId: number;
};

const createMatch = async (match: MatchRequest) => {
  const { data } = await apiClient.post(`${config.API_SERVER}v1/match`, match);
  return data;
};

export const useCreateMatch = () => {
  return useMutation({
    mutationFn: (match: MatchRequest) => createMatch(match),
  });
};

export type Match = {
  challenger: Player;
  challengerPoints: number;
  challengerScore: number;
  id: number;
  opponent: Player;
  opponentPoints: number;
  opponentScore: number;
};

export const useMatch = () => {
  return useQuery({
    queryKey: ["match"],
    queryFn: async (): Promise<Array<Match>> => {
      const { data } = await apiClient.get(`${config.API_SERVER}v1/match/`);
      console.log(data);
      return data;
    },
  });
};

export const useMatchPlayer = (playerId: number) => {
  return useQuery({
    queryKey: ["match", "player", playerId],
    queryFn: async (): Promise<Array<Match>> => {
      const { data } = await apiClient.get(
        `${config.API_SERVER}v1/match/player/${playerId}`
      );
      return data;
    },
  });
};

const getLeagues = async () => {
  const { data } = await apiClient.get<Array<League>>(
    `${config.API_SERVER}v1/league`
  );
  return data;
};

export const useLeauges = () => {
  return useQuery({
    queryKey: ["leagues"],
    queryFn: async () => getLeagues(),
  });
};

const joinLeague = async (leagueId: number) => {
  const { data } = await apiClient.post<League>(
    `${config.API_SERVER}v1/league/${leagueId}/join`
  );
  return data;
};

export const useJoinLeague = () => {
const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (leagueId: number) => joinLeague(leagueId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['match'] })
      queryClient.invalidateQueries({ queryKey: ['leagues'] })
      queryClient.invalidateQueries({ queryKey: ['leaderboard'] })
    },
  });
};

import { Box, Heading, Text } from "@chakra-ui/react";
import { useMatch } from "../../api/league";
import MatchTable from "../../components/MatchTable";
import Pagecontainer from "../../components/Pagecontainer";

const Match = () => {

  const { data, isLoading } = useMatch();

  if (isLoading) {
    return <Text>Loading ...</Text>;
  }

  return (
    <Pagecontainer>
      <Box p={4}>
        <Heading>Match</Heading>
        <MatchTable matches={data!} />
      </Box>
    </Pagecontainer>
  );

};

export default Match;
import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { AxiosError } from "axios";
import { useParams } from "react-router-dom";
import { useCreateMatch, useScoreboard } from "../../api/league";

type LeaderboardRouteParams = {
  leagueId: string
}

const Leaderboard = () => {
  const { leagueId } = useParams<LeaderboardRouteParams>()

  const { data, isLoading, isError, error } = useScoreboard(leagueId!);
  const { mutate } = useCreateMatch();

  
  if (isLoading) {
    return <Text>Loading ...</Text>;
  }

  if(isError) {
    return <Text color={'red'}>{(error as AxiosError).message}</Text>
  }


  return (
    <Box p={4}>
      <Heading>{`Leaderboard ${data?.name}`}</Heading>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th isNumeric>Punkte</Th>
              <Th isNumeric>Herausfordern</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.players?.map((entry) => {
              return (
                <Tr id={`player-${entry.id}`} key={`player-${entry.id}`}>
                  <Td>{entry.user.playerName ?? entry.user.firstName}</Td>
                  <Td isNumeric>{entry.points}</Td>
                  <Td isNumeric>
                    {entry.canBeChallenged && <Button
                      size={"sm"}
                      aria-label="challenge"
                      leftIcon={<AddIcon />}
                      onClick={() =>
                        mutate({ opponentId: entry.id, leagueId: data.id })
                      }
                    >{`(${entry.win} / ${entry.loss})`}</Button>}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Leaderboard;

import { Heading, Text } from "@chakra-ui/react";
import { usePosts } from "../api/news";
import Pagecontainer from "../components/Pagecontainer";
import { AxiosError } from "axios";

const Home = () => {

  const { data , isLoading, isError, error} = usePosts();

  if (isLoading) {
    return <Text>Loading ...</Text>;
  }

  if(isError) {
    return <Text color={'red'}>{(error as AxiosError).message}</Text>
  }


  return <Pagecontainer>
    <Heading>News</Heading>
    <Text>bla</Text>
    <Text>{JSON.stringify(data)}</Text>
  </Pagecontainer>;
};

export default Home;

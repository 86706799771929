import axios, { InternalAxiosRequestConfig } from "axios";
import config from "../services/config";
import { getUserAccessToken } from "../services/user";

const BASE_URL = config.API_SERVER;

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.request.use(
  (config : InternalAxiosRequestConfig) => {
      const token = getUserAccessToken();
      if (token) {
        config.headers.Authorization = "Bearer " + token;
      }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiClient;

import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/table";
import { Match } from "../api/league";

interface MatchTableProps {
  matches: Array<Match>;
}

const MatchTable = ({ matches }: MatchTableProps) => {
  return (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Name</Th>
            <Th>Ergebnis</Th>
          </Tr>
        </Thead>
        <Tbody>
          {matches?.map((entry) => {
            return (
              <Tr id={`player-${entry.id}`} key={`player-${entry.id}`}>
                <Td>
                  {entry.challenger.user.playerName ?? entry.challenger.user.firstName}
                </Td>
                <Td>{entry.opponent.user.playerName ?? entry.opponent.user.firstName}</Td>
                <Td
                  isNumeric
                >{`${entry.challengerScore} - ${entry.opponentScore}`}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default MatchTable;

import { LoginResponse } from "../api/auth";

const KEY = "user";

export const saveUserData = (user: LoginResponse) => {
  console.log(user);
  localStorage.setItem(KEY, JSON.stringify(user));
};

export const getUserData = (): LoginResponse | undefined => {
  try {
    return JSON.parse(localStorage.getItem(KEY) ?? "{}");
  } catch {
    return undefined
  }
};

export const getUserAccessToken = () => {
  const userData = getUserData()
  
  if(!userData) return undefined

  if( expired(userData.tokenExpires)) {
    
  }
  
  return userData?.token
};

export const logout = () => {
  localStorage.removeItem(KEY);
};

export const isLoggedIn = () : boolean => {
  return !!getUserAccessToken()
}

const expired = (exp: number) => {
    return Math.floor(new Date().getTime() / 1000) >= exp;  
}
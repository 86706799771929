import { useMutation } from "react-query";
import apiClient from "./_client";
import config from "../services/config";

export type LoginResponse = {
  token: string;
  refreshToken: string;
  tokenExpires: number;
  user: UserResponse;
};

type UserResponse = {
  id: number;
  email: string;
  provider: string;
  firstName: string;
  lastName: string;
  playerName?: string;
};

export type LoginData = {
  email: string;
  password: string;
};

export type ForgotPasswordData = {
  email: string;
};

export type SignUpData = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

const login = async (user: LoginData): Promise<LoginResponse> => {
  const { data } = await apiClient.post(`${config.API_SERVER}v1/auth/email/login`, user);
  return data;
};

const signup = async (user: SignUpData): Promise<LoginResponse> => {
  const { data } = await apiClient.post(`${config.API_SERVER}v1/auth/email/register`, user);
  return data;
};

const forgotPassword = async (passwordData: ForgotPasswordData) => {
  const { data } = await apiClient.post(`${config.API_SERVER}v1/auth/email/forgot/password`, passwordData)
  return data;
}

const refreshToken = async (refreshToken: string) => {
  const { data } = await apiClient.post(`${config.API_SERVER}v1/auth/refresh`,);
  return data
}

export const useLogin = () => {
  return useMutation({ mutationFn: (user: LoginData) => login(user) });
};

export const useSignUp = () => {
  return useMutation({ mutationFn: (signUpData: SignUpData) => signup(signUpData) })
}

export const useForgotPassword = () => {
  return useMutation({ mutationFn: (password: ForgotPasswordData) => forgotPassword(password) })
}
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserData } from "../services/user";
interface LinkObj {
  name: string;
  link: string;
  public?: boolean;
}

const Links: Array<LinkObj> = [
  { name: "Home", link: "/", public: true },
  { name: "Ligen", link: "leagues" },
  { name: "Matches", link: "match" },
];

const NavLink = ({ link }: { link: LinkObj }) => (
  <Link
    as={RouterLink}
    px={2}
    py={1}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: useColorModeValue("gray.200", "gray.700"),
    }}
    to={link.link}
  >
    {link.name}
  </Link>
);

export default function Simple() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState<string | undefined>(undefined);

  useEffect(() => {
    const user = getUserData()?.user;

    if (user) {
      setName(`${user.firstName} ${user.lastName}`);
    }
  }, []);

  useEffect(() => {
    console.log(name);
  }, [name]);

  return (
    <Box bg={useColorModeValue("green.300", "green.900")} px={4}>
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        <IconButton
          size={"md"}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={"center"}>
          <HStack as={"nav"} spacing={4} display={{ base: "none", md: "flex" }}>
            {Links.map((link) => (
              <NavLink key={link.name} link={link} />
            ))}
          </HStack>
        </HStack>
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              as={Button}
              rounded={"full"}
              variant={"link"}
              cursor={"pointer"}
              minW={0}
            >
              <Avatar size={"sm"} name={name} backgroundColor={"white"} color={"black"} />
            </MenuButton>
            <MenuList>
              {/* <MenuItem as={RouterLink} to={'profile'}>Profile</MenuItem> */}
              <MenuItem as={RouterLink} to={"mymatches"}>
                Meine Matches
              </MenuItem>
              <MenuDivider />
              <MenuItem as={RouterLink} to={"login"}>
                Login
              </MenuItem>
              <MenuItem as={RouterLink} to={"logout"}>
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: "none" }}>
          <Stack as={"nav"} spacing={4}>
            {Links.map((link) => (
              <NavLink link={link} />
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
}

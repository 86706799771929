import "./App.css";
import { ChakraProvider, Container, extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import MobileNav from "./components/Menu";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Leaderboard from "./pages/league/Leaderboard";
import NoMatch from "./pages/NoMatch";

import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import Match from "./pages/league/Match";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import SignupSuccess from "./pages/auth/SignUpSuccess";
import ConfirmEmail from "./pages/auth/ConfirmEmail";
import Leagues from "./pages/league/Leagues";
import MyMatches from "./pages/league/MyMatches";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: 5 * 1000,
    },
  },
})

const customTheme = extendTheme(
  withDefaultColorScheme({ colorScheme: 'green' }),
  {
    components: {
      Input: {
        defaultProps: {
          focusBorderColor: 'green.200',
        }
      }
    }
  })

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={customTheme}>
        <BrowserRouter>
          <MobileNav />
          <Container maxW={"7xl"} px="12" py="0">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="leagues" element={<Leagues />} />
              <Route path="leaderboard/:leagueId" element={<Leaderboard />} />
              <Route path="match" element={<Match />} />
              <Route path="mymatches" element={<MyMatches />} />
              <Route path="login" element={<Login />} />
              <Route path="signup" element={<Signup />} />
              <Route path="signup/success" element={<SignupSuccess />} />
              <Route path="confirm-email/:hash" element={<ConfirmEmail />} />
              <Route path="forgot" element={<ForgotPassword />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </Container>
        </BrowserRouter>
      </ChakraProvider>
    </QueryClientProvider>
  );
}

export default App;

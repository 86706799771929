import { AddIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Heading,
    Link,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr
} from "@chakra-ui/react";
import { useJoinLeague, useLeauges } from "../../api/league";
import { Link as RouterLink } from "react-router-dom";

const Leagues = () => {
    const { data, isLoading } = useLeauges();
    const { mutate } = useJoinLeague();

    if (isLoading) {
        return <Text>Loading ...</Text>;
    }

    return (
        <Box p={4}>
            <Heading>{`Leagues`}</Heading>
            <TableContainer>
                <Table variant="striped">
                    <Thead>
                        <Tr>
                            <Th>Liga</Th>
                            <Th isNumeric>Spieler</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data?.map((entry) => {
                            return (
                                <Tr id={`league-${entry.id}`} key={`league-${entry.id}`}>
                                    <Td><Link as={RouterLink} to={`/leaderboard/${entry.id}`}>{entry.name ?? entry.name}</Link></Td>
                                    <Td isNumeric>{entry.players.length}</Td>
                                    <Td isNumeric>
                                        <Button
                                            size={"sm"}
                                            variant={"outline"}
                                            aria-label="challenge"
                                            leftIcon={<AddIcon />}
                                            onClick={() =>
                                                mutate( entry.id )
                                            }
                                        >Beitreten</Button>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default Leagues;

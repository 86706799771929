import { useQuery } from "react-query";
import apiClient from "./_client";
import config from "../services/config";

type Post = {
  id: number;
  title: string;
  body: string;
};

export const usePosts = () => {
  return useQuery({
    queryKey: ["posts"],
    queryFn: async (): Promise<Array<Post>> => {
      console.log("news");
      const { data } = await apiClient.get(`${config.API_SERVER}news`);
      return data;
    },
    initialData: [],
  });
};

import { Box, Heading, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
const NoMatch = () => {
  return (
    <Box p={4}>
      <Heading>Nothing to see here!</Heading>
      <Link as={RouterLink} to="/">
        Go to the home page
      </Link>
    </Box>
  );
};

export default NoMatch;

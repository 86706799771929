import { Box, Heading, Text } from "@chakra-ui/react";
import { useMatchPlayer } from "../../api/league";
import MatchTable from "../../components/MatchTable";
import Pagecontainer from "../../components/Pagecontainer";

const MyMatches = () => {

  const { data, isLoading } = useMatchPlayer(1);

  if (isLoading) {
    return <Text>Loading ...</Text>;
  }

  return (
    <Pagecontainer>
      <Box p={4}>
        <Heading>Match</Heading>
        <MatchTable matches={data!} />
      </Box>
    </Pagecontainer>
  );

};

export default MyMatches;
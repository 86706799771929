import { Box } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

interface PagecontainerProps {
    children: ReactNode
}

const Pagecontainer = ({children}: PagecontainerProps): ReactElement => {
    return <Box p={4}>{children}</Box>;
}

export default Pagecontainer